import { LoryStatic } from "../../../node_modules/@types/lory.js/index";

/**
 * Slides up the timeline description for reading.
 */
export function timelineSlide() {
    const readMore = document.querySelectorAll(".timeline-bottom");

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < readMore.length; i++) {
        readMore[i].addEventListener("click", function (this: HTMLElement) {
            this.classList.toggle("timeline-selected");
        });
    }
}

export function clickYear(timeline: LoryStatic) {
    const years = document.querySelectorAll(".timeline-years li a");

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < years.length; i++) {
        years[i].addEventListener("click", function (this: HTMLElement) {
            const year = this.dataset.year;
            const index = (document.querySelector(`.js_slide[data-hash="${year}"]`) as HTMLElement)!.dataset
                .slide as string;
            timeline.slideTo(parseInt(index, 10));
        });
    }
}

/**
 * Highlights the active year.
 */
export function highlightYear() {
    document.querySelector(".timeline-years .redYear")!.classList.remove("redYear");
    document.querySelector(`[data-year="${getYear()}"]`)!.classList.add("redYear");
}

/**
 * Highlights the start year.
 */
export function startYear() {
    document.querySelector(`[data-year="${getYear()}"]`)!.classList.add("redYear");
}

function getYear() {
    const slider = document.querySelector(".timeline-slider");
    const active = slider!.querySelector(".active") as HTMLElement;
    const year = active!.dataset.hash;

    return year;
}
