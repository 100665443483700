import { lory } from "lory.js";
import { sliderMovedEvent } from "./modules/gaEvents";
import { clickYear, highlightYear, startYear, timelineSlide } from "./modules/timelineSlide";

document.addEventListener("DOMContentLoaded", () => {
    const celebSlider = document.querySelector(".celebrity-slider");
    const champSlider = document.querySelector(".champion-slider");
    const timelineSlider = document.querySelector(".timeline-slider");
    const pressHeader = document.querySelector(".pressheader-slider");
    const recentSlider = document.querySelector(".recent-slider");
    const celebGrid = document.querySelector(".celeb-10-grid");

    if (celebSlider) {
        lory(celebSlider, {
            enableMouseEvents: true,
        });

        celebSlider.addEventListener("after.lory.slide", () => {
            sliderMovedEvent("Celebrity Slider");
        });
    }

    if (champSlider) {
        lory(champSlider, {
            enableMouseEvents: true,
        });

        champSlider.addEventListener("after.lory.slide", () => {
            sliderMovedEvent("Champion Slider");
        });
    }

    if (timelineSlider) {
        const timeline = lory(timelineSlider, {
            enableMouseEvents: true,
        });
        timeline.slideTo(10);
        startYear();
        clickYear(timeline);
        timelineSlide();

        timelineSlider.addEventListener("after.lory.slide", () => {
            highlightYear();
            sliderMovedEvent("Timeline Slider");
        });
    }

    if (pressHeader) {
        lory(pressHeader, {
            enableMouseEvents: true,
        });

        pressHeader.addEventListener("after.lory.slide", () => {
            sliderMovedEvent("Newssroom Header Slider");
        });
    }

    if (recentSlider) {
        lory(recentSlider, {
            enableMouseEvents: true,
        });

        recentSlider.addEventListener("after.lory.slide", () => {
            sliderMovedEvent("Newsroom Recent Slider");
        });
    }

    if (celebGrid) {
        // Checks custom event fired once the celeb modal is totes loaded
        celebGrid.addEventListener("celebModalLoaded", () => {
            const celeb10Slide = document.querySelector(".celeb-10-slider");
            if (celeb10Slide) {
                lory(celeb10Slide, {
                    enableMouseEvents: true,
                    infinite: 1,
                });
            }
        });
    }
});
